export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CURRENT_MENU_ITEM = 'current_menu_item';
export const CHANGE_BOX_NUMBER = 'change_box_number';
export const LOAD_INBOX_LOADING = 'load_inbox_loading';
export const LOAD_INBOX_SUCCESS = 'load_inbox_success';
export const LOAD_INBOX_ERROR = 'load_inbox_error';
export const SHOW_INBOX_MODAL = 'show_inbox_modal';
export const HIDE_INBOX_MODAL = 'hide_inbox_modal';
export const HANDLE_CHANGE_INBOX = 'handle_change_inbox';
export const LOAD_POOLS_LOADING = 'load_pools_loading';
export const LOAD_POOLS_SUCCESS = 'load_pools_success';
export const LOAD_POOLS_ERROR = 'load_pools_error';
export const LOAD_POOLS_EMPTY = 'load_pools_empty';
export const SET_POOL_DATAINDEX = 'set_pool_dataindex';
export const SHOW_POOL_MODAL = 'show_pool_modal';
export const HIDE_POOL_MODAL = 'hide_pool_modal';
export const GET_SELECTED_POOLS = 'get_selected_pools';
export const SUPER_POOL_VALUE = 'super_pool_value';
export const SUBMIT_POOLS_LOADING = 'submit_pools_loading';
export const SUBMIT_POOLS_SUCCESS = 'submit_pools_success';
export const SUBMIT_POOLS_ERROR = 'submit_pools_error';
export const DELETE_POOLS_LOADING = 'delete_pools_loading';
export const DELETE_POOLS_SUCCESS = 'delete_pools_success';
export const DELETE_POOLS_ERROR = 'delete_pools_error';
export const FINISH_PLATE = 'finish_plate';
export const PLATE_MODAL_SHOW = 'plate_modal_show';
export const PLATE_MODAL_HIDE = 'plate_modal_hide';
export const PLATE_CHANGE_VALUE = 'plate_change_value';
export const PLATE_LOAD_LOADING = 'plate_load_loading';
export const PLATE_LOAD_SUCCESS = 'plate_load_success';
export const PLATE_LOAD_ERROR = 'plate_load_error';
export const CHANGE_PLATE_NUMBER = 'change_plate_number';
export const LOAD_RESULTS_LOADING = 'load_results_loading';
export const LOAD_RESULTS_SUCCESS = 'load_results_success';
export const LOAD_RESULTS_ERROR = 'load_results_error';
export const RESULTS_CHANGE_VALUE = 'results_change_value';
export const RESULTS_SET_KEY = 'results_set_key';
export const SUBMIT_RESULTS_LOADING = 'submit_results_loading';
export const SUBMIT_RESULTS_SUCCESS = 'submit_results_success';
export const SUBMIT_RESULTS_ERROR = 'submit_results_error';
export const SHOW_SIDEBAR = 'show_sidebar';
export const HIDE_SIDEBAR = 'hide_sidebar';
export const SIDEBAR_FETCH_POOLS_LOADING = 'sidebar_fetch_pools_loading';
export const SIDEBAR_FETCH_POOLS_SUCCESS = 'sidebar_fetch_pools_success';
export const SIDEBAR_FETCH_POOLS_ERROR = 'sidebar_fetch_pools_error';
export const SIDEBAR_FETCH_SUPERPOOLS_LOADING = 'sidebar_fetch_superpools_loading';
export const SIDEBAR_FETCH_SUPERPOOLS_SUCCESS = 'sidebar_fetch_superpools_success';
export const SIDEBAR_FETCH_SUPERPOOLS_ERROR = 'sidebar_fetch_superpools_error';
export const SHOW_RIGHT_SIDEBAR = 'show_right_sidebar';
export const LINE_CHART_LOADING = 'line_chart_loading';
export const LINE_CHART_SUCCESS = 'line_chart_success';
export const LINE_CHART_ERROR = 'line_chart_error';
export const WEEK_CHART_LOADING = 'week_chart_loading';
export const WEEK_CHART_SUCCESS = 'week_chart_success';
export const WEEK_CHART_ERROR = 'week_chart_error';
export const GET_LAB_USERS = 'get_lab_users';
export const SET_ID_TECH = 'set_id_tech';
export const SET_EX_TECH = 'set_ex_tech';
export const POOLS_DATA_LOADING = 'pools_data_loading';
export const POOLS_DATA_SUCCESS = 'pools_data_success';
export const POOLS_DATA_ERROR = 'pools_data_error';
export const POOLS_CONFIRM_SHOW = 'pools_confirm_show';
export const POOLS_CONFIRM_HIDE = 'pools_confirm_hide';
export const POOLS_MODAL_SHOW = 'pools_modal_show';
export const POOLS_MODAL_HIDE = 'pools_modal_hide';
export const POSITIVE_RESULTS_SHOW = 'positive_results_show';
export const POSITIVE_RESULTS_HIDE = 'positive_results_hide';
export const POSITIVE_RESULTS_LOADING = 'positive_results_loading';
export const POSITIVE_RESULTS_SUCCESS = 'positive_results_success';
export const POSITIVE_RESULTS_ERROR = 'positive_results_error';
export const POSITIVE_RESULTS_POOL = 'positive_results_pool';
export const POSITIVE_RESULTS_CLEAR = 'positive_results_clear';
export const QUICK_FACTS_LOADING = 'quick_facts_loading';
export const QUICK_FACTS_SUCCESS = 'quick_facts_success';
export const QUICK_FACTS_ERROR = 'quick_facts_error';
export const TOAST_ADD_ITEM = 'toast_add_item';
export const TOAST_DELETE_ITEM = 'toast_delete_item';
export const TOAST_AUTO_DELETE = 'toast_auto_delete';
export const CODES_DATA_LOADING = 'codes_data_loading';
export const CODES_DATA_SUCCESS = 'codes_data_success';
export const CODES_DATA_ERROR = 'codes_data_error';
export const INBOX_SET_ACTIVE = 'inbox_set_active';
export const INBOX_SET_VALUE = 'INBOX_SET_SCAN_VALUE';
export const LOAD_RECORD_POOLS_LOADING = 'load_record_pools_loading';
export const LOAD_RECORD_POOLS_SUCCESS = 'load_record_pools_success';
export const LOAD_RECORD_POOLS_ERROR = 'load_record_pools_error';
export const CHECK_VALUE_OPEN = 'check_value_open';
export const CHECK_VALUE_CLOSE = 'check_value_close';
export const SET_MENU_COLOR = 'set_menu_color';
export const SHOW_BALLOON = 'show_balloon';
export const HIDE_BALLOON = 'hide_balloon';
export const SET_ARBOVIRUS_POOLS = 'set_arbovirus_pools';
export const SET_ARBOVIRUS_PLATE = 'set_arbovirus_plate';
export const SET_ARBOVIRUS_RESULTS = 'set_arbovirus_results';
export const SET_ARBOVIRUS_SEQ_PLATE = 'set_arbovirus_seq_plate';
export const SET_WOLBACHIA_PLATE = 'set_wolbachia_plate';
export const SET_WOLBACHIA_RESULTS = 'set_wolbachia_results';
export const FETCH_MESSAGES_LOADING = 'fetch_messages_loading';
export const FETCH_MESSAGES_SUCCESS = 'fetch_messages_success';
export const FETCH_MESSAGES_ERROR = 'fetch_messages_error';
export const MESSAGES_SIDEBAR_OPEN = 'messages_sidebar_open';
export const MESSAGES_SIDEBAR_CLOSE = 'messages_sidebar_close';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_BOXES_LOADING = 'fetch_boxes_loading';
export const FETCH_BOXES_SUCCESS = 'fetch_boxes_success';
export const FETCH_BOXES_ERROR = 'fetch_boxes_error';
export const LARVAE_INBOX_LOADING = 'LARVAE_INBOX_LOADING';
export const LARVAE_INBOX_SUCCESS = 'LARVAE_INBOX_SUCCESS';
export const LARVAE_INBOX_ERROR = 'LARVAE_INBOX_ERROR';
export const LARVAE_INBOX_ACTIVE = 'LARVAE_INBOX_ACTIVE';
export const CONFIRM_NOT_FOUND = 'CONFIRM_NOT_FOUND';
export const CONFIRM_DUPLICATED = 'CONFIRM_DUPLICATED';
export const ORDERS_SEARCH_VALUE = 'ORDERS_SEARCH_VALUE';
export const ORDERS_DATA_LOADING = 'ORDERS_DATA_LOADING';
export const ORDERS_DATA_SUCCESS = 'ORDERS_DATA_SUCCESS';
export const ORDERS_DATA_ERROR = 'ORDERS_DATA_ERROR';
export const ORDERS_MODAL_VISIBILITY = 'ORDERS_MODAL_VISIBILITY';
export const ORDERS_SET_RECORD = 'ORDERS_SET_RECORD';
export const ORDERS_CONFIRMATION_VISIBILITY = 'ORDERS_CONFIRMATION_VISIBILITY';
export const CUSTOMER_ORDERS_LOADING = 'CUSTOMER_ORDERS_LOADING';
export const CUSTOMER_ORDERS_ERROR = 'CUSTOMER_ORDERS_ERROR';
export const CUSTOMER_ORDERS_SUCCESS = 'CUSTOMER_ORDERS_SUCCESS';
export const CUSTOMER_ORDERS_ORDER = 'CUSTOMER_ORDERS_ORDER';
export const CUSTOMER_ORDERS_POOLS = 'CUSTOMER_ORDERS_POOLS';
export const CUSTOMER_ORDER_POOL = 'CUSTOMER_ORDER_POOL';
export const CUSTOMER_POOLS_LOADING = 'CUSTOMER_POOLS_LOADING';
export const CUSTOMER_POOLS_ERROR = 'CUSTOMER_POOLS_ERROR';
export const CUSTOMER_POOLS_SUCCESS = 'CUSTOMER_POOLS_SUCCESS';
