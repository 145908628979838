import React, { Component } from 'react';
import {
	Button,
	Form,
	Grid,
	Input,
	Popup,
	Table,
	Container
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import { Formik } from 'formik';
import {
	deletePools,
	getSelected,
	handleValue,
	hidePoolModal,
	loadPools,
	setDataIndex,
	showPoolModal,
	submitPools,
	loadBoxes
} from '../../../../store/actions/PoolsActions';
import Badge from '../../../shared-components/Badge';
import FormLabel from '../../../shared-components/FormLabel';
import Switch from '../../../shared-components/Switch';
import { getAdmin } from '../../../auth-utils';

class Pools extends Component {
	constructor(props) {
		super(props);
		this.state = {
			term: ''
		};
	}

	componentDidMount() {
		this.timer = null;
	}

	handleChange = (value, checked, index) => {
		this.props.data[index].checked = !this.props.data[index].checked;

		if (checked) {
			const array = _.concat(this.props.pools, value);
			this.props.getSelected(array);
		} else {
			const array = _.remove(this.props.pools, n => {
				return n !== value;
			});
			this.props.getSelected(array);
		}
	};

	render() {
		return (
			<Container text>
				<Grid stackable>
					<Grid.Row columns={1} centered>
						<Grid.Column width={8}>
							<Form size="small">
								<Form.Field
									disabled={!getAdmin(this.props.role)}
									fluid
									size="small"
									icon={this.props.loading ? 'spinner' : 'box'}
									loading={this.props.loading}
									placeholder="Box number..."
									control={Input}
									onChange={(e, { value }) => {
										setTimeout(() => {
											this.props.loadPools(value);
										}, 200);
									}}
								/>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column>
							{this.props.data.length > 0 ? (
								<div className="pre">
									<div className="pre-header x-small p-10 strong">
										Box ({this.props.data.length} Pools)
									</div>
									<div
										className="p-10"
										style={{
											borderBottom: '1px solid rgba(0,0,0,0.1)'
										}}
									>
										<Form size="small">
											<Form.Group
												style={{
													margin: 0,
													display: 'flex',
													justifyContent: 'space-between'
												}}
											>
												<Form.Field
													className="p-0"
													icon="filter"
													size="small"
													placeholder="Filter pools..."
													control={Input}
													onChange={e => {
														this.setState({
															term: e.target.value
														});
														this.props.setDataIndex(e.target.value, '');
													}}
												/>
												{this.props.pools.length > 0 && (
													<Input
														className="p-0"
														tyle={{ padding: 0 }}
														icon="lab"
														size="small"
														placeholder="Add super pool..."
														value={
															this.props.superpool ? this.props.superpool : ''
														}
														onChange={e => {
															this.props.handleValue(
																'superpool',
																e.target.value
															);
														}}
														onKeyUp={() => {
															if (this.timer) clearTimeout(this.timer);
															this.timer = setTimeout(() => {
																this.props.submitPools(
																	this.props.superpool,
																	this.props.pools,
																	this.props.email,
																	this.props.box
																);
															}, 500);
														}}
													/>
												)}
											</Form.Group>
										</Form>
									</div>
									<Table
										striped
										singleLine
										unstackable
										basic="very"
										size="small"
										tableData={matchSorter(this.props.data, this.state.term, {
											keys: ['pool']
										})}
										renderBodyRow={(item, index) => {
											return (
												<Table.Row key={item.id}>
													<Table.Cell width={1} textAlign="center">
														<Switch
															name={item.pool}
															value={item.key}
															checked={item.checked}
															onChange={e => {
																this.handleChange(
																	e.target.value,
																	e.target.checked,
																	index
																);
															}}
														/>
													</Table.Cell>
													<Table.Cell width={7}>
														<div className="small quiet">
															{item.pool} (
															<span
																className={`${
																	item.status === 'Success' ? 'green' : 'orange'
																}`}
															>
																{item.status}
															</span>
															)
														</div>
														<div className="quiet small">
															<Badge color="blue" style={{ marginRight: 5 }}>
																{item.mosquitoes} 🦟
															</Badge>{' '}
															{new Date(item.date).toDateString()}
														</div>
													</Table.Cell>
													<Table.Cell width={4} textAlign="center">
														<span className="quiet small">
															{item.superpool}
														</span>
													</Table.Cell>
													<Table.Cell width={2}>
														<Popup
															position="top right"
															on="click"
															size="mini"
															content={() => {
																return (
																	<div>
																		<p className="strong">Edit Super Pool</p>
																		<p className="quiet">
																			Add super pool number to the selected
																			pool.
																		</p>
																		<Formik
																			initialValues={{
																				superpool: item.superpool
																					? item.superpool
																					: ''
																			}}
																			onSubmit={values => {
																				this.props.submitPools(
																					values.superpool,
																					this.props.dataIndex,
																					this.props.email,
																					this.props.box
																				);
																			}}
																		>
																			{props => {
																				const {
																					values,
																					handleChange,
																					handleBlur,
																					handleSubmit
																				} = props;
																				return (
																					<Form
																						size="mini"
																						onSubmit={handleSubmit}
																					>
																						<Form.Field>
																							<FormLabel text="Super Pool" />
																							<Input
																								name="superpool"
																								value={values.superpool}
																								onChange={handleChange}
																								onBlur={handleBlur}
																							/>
																						</Form.Field>
																						<Form.Button
																							type="submit"
																							primary
																							size="mini"
																							content="Save"
																							loading={this.props.poolLoading}
																						/>
																					</Form>
																				);
																			}}
																		</Formik>
																	</div>
																);
															}}
															trigger={
																<Button
																	fluid
																	primary
																	size="mini"
																	content="Edit"
																	onClick={() => {
																		this.props.setDataIndex(
																			item.pool,
																			item.superpool
																		);
																	}}
																/>
															}
														/>
													</Table.Cell>
												</Table.Row>
											);
										}}
									/>
								</div>
							) : (
								<div className="pre">
									<div className="pre-header x-small p-10 strong">Box</div>
									{this.props.loading ? (
										<div className="pre-loading x-small p-10 strong">
											Loading
										</div>
									) : (
										<div className="pre-loading x-small p-10 strong">
											{getAdmin(this.props.role)
												? 'Enter box number on the input field to load box'
												: 'You have no privileges to access this section'}
										</div>
									)}
									<div>
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
									</div>
								</div>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		role: state.auth.role,
		authenticated: state.auth.authenticated,
		name: state.auth.givenName,
		email: state.auth.email,
		value: state.pools.value,
		visible: state.pools.visible,
		data: state.pools.data,
		loading: state.pools.loading,
		error: state.pools.error,
		date: state.pools.date,
		pools: state.pools.pools,
		poolLoading: state.pools.poolLoading,
		superpool: state.pools.superpool,
		dataIndex: state.pools.dataIndex,
		source: state.pools.source,
		box: state.pools.box,
		boxes: state.pools.boxes
	};
};

export default connect(
	mapStateToProps,
	{
		getSelected,
		showPoolModal,
		hidePoolModal,
		loadPools,
		submitPools,
		handleValue,
		deletePools,
		setDataIndex,
		loadBoxes
	}
)(Pools);
